import { t } from '@/i18next';

import {
  addUserNote,
  bulkCreateNotes,
  bulkDeleteNotes,
  deleteUserNote,
  editUserNote
} from '@/api/user';

import {
  ADD_EDIT_NOTE_MODAL,
  BULK_CREATE_NOTES_MODAL,
  BULK_DELETE_NOTES_MODAL,
  DELETE_ENTITY_MODAL
} from '@/constants/modals';

import { USERS_API } from '@/constants/api';

import type {
  BulkCreateNotesParams,
  BulkDeleteNotesParams,
  SingleNoteParams
} from '@/api/user/types';

import type { ListUser } from '@/types/users';
import type { PromiseResolve } from '@/types/helpers';
import type { UseModal } from '@/types/modals';

export function openAddNoteModal(
  openModal: UseModal['openModal'],
  user: ListUser
) {
  async function addNoteHandler(
    resolve: PromiseResolve,
    noteParams: SingleNoteParams
  ) {
    const data = await addUserNote(noteParams);
    resolve(data);
  }

  openModal<typeof ADD_EDIT_NOTE_MODAL>(ADD_EDIT_NOTE_MODAL, {
    confirmAction: addNoteHandler,
    useStatusConfirmation: true,
    invalidateQueryKey: USERS_API,
    allowGoBack: true,
    user,
    successMessage: 'manageAccounts.add_note_success'
  });
}

export function openEditNoteModal(
  openModal: UseModal['openModal'],
  user: ListUser
) {
  async function editNoteHandler(
    resolve: PromiseResolve,
    noteParams: SingleNoteParams
  ) {
    const data = await editUserNote(noteParams);
    resolve(data);
  }

  openModal<typeof ADD_EDIT_NOTE_MODAL>(ADD_EDIT_NOTE_MODAL, {
    confirmAction: editNoteHandler,
    useStatusConfirmation: true,
    invalidateQueryKey: USERS_API,
    allowGoBack: true,
    user,
    successMessage: 'manageAccounts.edit_note_success'
  });
}

export function openDeleteNoteModal(
  openModal: UseModal['openModal'],
  user: ListUser
) {
  async function onDeleteNoteHandler(resolve: PromiseResolve) {
    const data = await deleteUserNote(user.id);
    resolve(data);
  }
  const entityName = `${user.fullName} (${user.email})`;

  openModal<typeof DELETE_ENTITY_MODAL>(DELETE_ENTITY_MODAL, {
    confirmAction: onDeleteNoteHandler,
    useStatusConfirmation: true,
    invalidateQueryKey: USERS_API,
    name: entityName,
    confirmTranslation: 'common.delete',
    modalMessage: 'manageAccounts.delete_note',
    successMessage: 'common.deletedSuccessfully',
    successMessageParams: {
      entityType: t('manageAccounts.note'),
      entityName
    }
  });
}

export async function openBulkDeleteNotesModal(
  openModal: UseModal['openModal']
) {
  async function bulkDeleteNotesHandler(
    resolve: PromiseResolve,
    bulkDeleteParams: BulkDeleteNotesParams
  ) {
    const data = await bulkDeleteNotes(bulkDeleteParams);
    resolve(data);
  }

  openModal<typeof BULK_DELETE_NOTES_MODAL>(BULK_DELETE_NOTES_MODAL, {
    confirmAction: bulkDeleteNotesHandler,
    useStatusConfirmation: true,
    isBulkAction: true,
    invalidateQueryKey: USERS_API,
    successMessage: 'manageAccounts.bulk_delete_notes_started'
  });
}

export async function openBulkCreateNotesModal(
  openModal: UseModal['openModal']
) {
  async function bulkCreateNotesHandler(
    resolve: PromiseResolve,
    bulkCreateParams: BulkCreateNotesParams
  ) {
    const data = await bulkCreateNotes(bulkCreateParams);
    resolve(data);
  }

  openModal<typeof BULK_CREATE_NOTES_MODAL>(BULK_CREATE_NOTES_MODAL, {
    confirmAction: bulkCreateNotesHandler,
    useStatusConfirmation: true,
    isBulkAction: true,
    invalidateQueryKey: USERS_API,
    successMessage: 'manageAccounts.bulk_create_notes_started'
  });
}
